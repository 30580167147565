<template>
  <div class="gov-apply-card-container center">
    <div class="gov-apply-card column">

      <span clsas="gov-apply-card-title">
        <p class="gov-apply-card-title-text">국민내일배움카드(직업능력개발계좌제)</p>
      </span>

      <span clsas="gov-apply-card-content">
        <p class="gov-apply-card-content-text">국비지원은 취업을 희망하는 국민을 대상으로 직업훈련을 부담없이 받을 수 있도록 국가에서 훈련비용을 지원하는 제도입니다.</p>
        <p class="gov-apply-card-content-text">내일배움카드를 발급받아 소지하고 있을 경우에 국비 지원 교육과정을 운영 중인 교육기관에서 직업훈련을 받을 수 있는 자격이 주어집니다.</p>
      </span>

      <span class="gov-apply-card-sub-title">
        <p class="gov-apply-card-sub-title-text">국민내일배움카드 발급대상자</p>
      </span>

      <hr>
      <table class="gov-apply-card-table">
        <tr class="gov-apply-card-table-header">
          <th
          v-for="(item, index) in applyCardTable.header"
          :key="'table-header-'+index">
          {{item}}
          </th>
        </tr>

        <tr class="gov-apply-card-table-content"
        v-for="(subItem, subIndex) in applyCardTable.content"
        :key="'table-sub-header-'+subIndex">
          <td>{{subItem.subHeader}}</td>
          <td>
            <p
            v-for="(subContent, subContentIndex) in subItem.subContent"
            :key="'table-sub-content-'+subContentIndex">{{subContent}}</p>
          </td>
        </tr>

      </table>

    </div>
  </div>
</template>

<script>
import { applyCardTable } from '../../data/gov';

export default {
  data() {
    return {
      applyCardTable,
    }
  }
}
</script>

<style>

</style>